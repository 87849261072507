import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
// import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { AppRoutingModule } from "./routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Store } from "./store";

// Firebase
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

const config = {
  apiKey: "AIzaSyCGRopEZBChQLE6Z1_ph-bmwpQTPpJYwgk",
  authDomain: "days-go-by.firebaseapp.com",
  projectId: "days-go-by",
  storageBucket: "days-go-by.appspot.com",
  messagingSenderId: "521556040611",
  appId: "1:521556040611:web:cb9866c0d5c8acfb221816",
  measurementId: "G-3R45DMTZRZ",
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // HttpClientModule,
    // HttpClientJsonpModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(config),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [Store],
  bootstrap: [AppComponent],
})
export class AppModule {}
